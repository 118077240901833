import React, { useContext, useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Breadcrumb, Layout, Menu, Spin, theme } from 'antd'
import { Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { AppContext } from '../../hooks/AppContext'

const { Header, Content, Sider } = Layout

const items1: MenuProps['items'] = ['1', '2', '3'].map((key) => ({
  key,
  label: `nav ${key}`
}))

const SideBarMenu = [
  {
    label: '絵本生成',
    key: 1,
    icon: UserOutlined,
    route: 'txt2img'
  },
  {
    label: '絵本生成履歴',
    key: 3,
    icon: UserOutlined,
    route: 'photo-library'
  },
  {
    label: 'ログアウト',
    key: 5,
    icon: UserOutlined,
    route: 'login'
  }
]

const BaseLayout = (props: any) => {
  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken()
  const navigate = useNavigate()

  const location = useLocation()
  const { menuSelected, setMenuSelected, isMobile } = useContext(AppContext)

  const items2: MenuProps['items'] = SideBarMenu.map((e) => {
    return {
      key: `${e.key}`,
      label: `${e.label}`,
      onClick: () => {
        if (e.route == 'login') {
          localStorage.clear()
        }
        navigate(e.route)
      }
    }
  })

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/login')
    }
  }, [])

  return (
    <Layout>
      <Layout>
        {!location.pathname.includes('login') && (
          <Sider
            width={210}
            style={{ background: colorBgContainer }}
            // collapsible
            collapsed={isMobile}
            collapsedWidth={isMobile ? 40 : undefined}
          >
            <Menu
              mode='inline'
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ height: '100%', borderRight: 0 }}
              items={items2}
              selectedKeys={[`${menuSelected}`]}
              onClick={(item) => {
                if (item.key === '5') {
                  localStorage.clear()
                }
                setMenuSelected(item.key)
              }}
            />
          </Sider>
        )}
        <Layout
          style={{ padding: '0 24px 24px', minHeight: window.innerHeight }}
        >
          <Breadcrumb>
            {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>List</Breadcrumb.Item>
                        <Breadcrumb.Item>App</Breadcrumb.Item> */}
            <h3></h3>
          </Breadcrumb>
          <Content
            style={{
              paddingLeft: 15,
              paddingRight: 10,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              height: '100%'
            }}
          >
            {localStorage.getItem('token') ? (
              <Outlet />
            ) : (
              <Navigate to='/login' replace />
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default BaseLayout
