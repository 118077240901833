import { useRef, useState } from 'react'
import type { CarouselRef } from 'antd/es/carousel'
import { Carousel, Radio, Modal, Button, Space, Flex } from 'antd'
import { MangaBookType } from '../types/manga-book.type'

type MangaReadingHookType = {
  isShowMangaReadingModal: boolean
  showMangaReadingModal: (mangaBookData?: MangaBookType) => void
  hideMangaReadingModal: () => void
  onPreviouseCarouselClick: () => void
  onNextCarouselClick: () => void
  carouselRef: React.MutableRefObject<CarouselRef | null>
  selectedMangaBookData: MangaBookType | undefined
  selectCount: number
}

const useMangaReading: () => MangaReadingHookType = () => {
  const [isShowMangaReadingModal, setIsShowMangaReadingModal] = useState(false)
  const carouselRef = useRef<CarouselRef | null>(null)
  const [selectedMangaBookData, setSelectedMangaBookData] = useState<
    MangaBookType | undefined
  >(undefined)
  const [selectCount, setSelectCount] = useState<number>(0)

  const showMangaReadingModal = (mangaBookData?: MangaBookType) => {
    console.log('mangaBookData:', mangaBookData)
    setSelectedMangaBookData(mangaBookData)
    setSelectCount(selectCount + 1)
    setIsShowMangaReadingModal(true)
  }

  const hideMangaReadingModal = () => {
    setIsShowMangaReadingModal(false)
  }

  const onPreviouseCarouselClick = () => {
    carouselRef.current?.prev()
  }

  const onNextCarouselClick = () => {
    carouselRef.current?.next()
  }

  return {
    isShowMangaReadingModal,
    showMangaReadingModal,
    hideMangaReadingModal,
    onPreviouseCarouselClick,
    onNextCarouselClick,
    carouselRef,
    selectedMangaBookData,
    selectCount
  }
}

export default useMangaReading
