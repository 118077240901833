import * as Yup from 'yup'
import * as _ from 'lodash'
import { MangaInputInforType } from '../types/manga-book.type'

const createMangaValidation = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('名前を入力してください。')
    .max(500, '500文字以下入力してください。'),
  interest: Yup.string().nullable().required('興味を入力してください'),
  educationInput: Yup.string()
    .nullable()
    .required('教育的な要素を入力してください。')
})

const isMangaValidationInfo = async (mangaData: MangaInputInforType) => {
  try {
    let res = await createMangaValidation.validate(mangaData, {
      abortEarly: false
    })
    return res
  } catch (error: any) {
    console.log(error)
    let errorMessages = error

    if (error.inner) {
      console.log(error.inner)
      errorMessages = _.keyBy(error.inner, 'path')
    }

    throw errorMessages
  }
}

export { createMangaValidation, isMangaValidationInfo }
