import { Flex, Typography } from 'antd'
import { useContext } from 'react'

import {
  MangaBookType,
  MangaInforType,
  UrlType
} from '../../../types/manga-book.type'
import MangaPictureItem from '../../Generate/components/MangaPictureItem'
import moment from 'moment'
import { pdf } from '@react-pdf/renderer'
import MyDocumentPDF from '../../Generate/components/MyDocumentPDF'
import { saveAs } from 'file-saver'
import { AppContext } from '../../../hooks/AppContext'

type Props = {
  item: MangaInforType
  index: number
  showMangaReadingModal: (item: any) => void
  setMangaSelectIndex: any
  isMangaProcessing?: boolean
  onCheckBoxImage?: any
}

const MangaHistoryItem = (props: Props) => {
  const {
    item,
    index,
    showMangaReadingModal,
    setMangaSelectIndex,
    isMangaProcessing = false,
    onCheckBoxImage
  } = props
  const { showGenerateCompleted } = useContext(AppContext)

  const generatePdfDocument = async () => {
    showGenerateCompleted(
      'ファイルをダウンロード中です。大変恐れ入りますが、少々お待ちください。'
    )
    const blob = await pdf(
      <MyDocumentPDF
        mangaList={item}
        mangaOverview={{ title: item?.title, overview: item?.overview }}
      />
    ).toBlob()
    saveAs(blob, item?.title)
  }

  return (
    <div>
      <Flex className='mt-7'>
        <Typography className='font-bold  text-[16px] mb-2'>
          {moment(item?.updated_at).format('YYYY/MM/DD  HH:mm')}
        </Typography>
        {!isMangaProcessing && (
          <img
            src={require('../../assets/images/download.png')}
            className='w-6 h-6 ml-3 cursor-pointer'
            onClick={generatePdfDocument}
          />
        )}
      </Flex>

      <div>
        <Typography className='flex'>
          <strong>タイトル：</strong>
          {item?.title}
        </Typography>
        <Typography>
          <strong>概要：</strong>
          {item?.overview}
        </Typography>
      </div>
      <Flex className='flex-wrap max-h-[300px] overflow-auto mt-5 '>
        {item?.pages?.map((item: MangaBookType) => (
          <MangaPictureItem
            item={item}
            key={item?.id}
            onItemClick={() => {
              showMangaReadingModal(item)
              setMangaSelectIndex(index)
            }}
            onCheckBoxImage={(imageUrl: UrlType) => {
              onCheckBoxImage(imageUrl)
            }}
            isMangaProcessing={isMangaProcessing}
          />
        ))}
      </Flex>
    </div>
  )
}
export default MangaHistoryItem
