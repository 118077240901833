import { Button, Col, Flex, Image, Row, Spin, Table, Typography } from 'antd'
import React, { memo, useContext, useState } from 'react'
import { ContentMangaType } from '../../../types/manga-book.type'

type Props = {
  handleRequestMangaImg: () => void
  contentList: any
}

const MangaContent = (props: Props) => {
  const { handleRequestMangaImg, contentList } = props
  const columns = [
    {
      title: 'ページ',
      dataIndex: 'page',
      width: 80
    },
    {
      title: 'ストーリ',
      dataIndex: 'content'
    }
  ]

  return (
    <div>
      <Table
        columns={columns}
        dataSource={contentList}
        pagination={{
          defaultPageSize: 16,
          hideOnSinglePage: true
        }}
      />
      <Flex justify='center' className='mb-8 mt-6'>
        <Button
          className='bg-[#ED991B] text-[#ffffff] font-bold w-[210px] mt-4'
          onClick={handleRequestMangaImg}
        >
          ストーリー画像を生成する
        </Button>
      </Flex>
    </div>
  )
}

export default MangaContent
