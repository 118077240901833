import { Card, Checkbox, Flex } from 'antd'
import '../../../App.css'
import { MangaBookType, UrlType } from '../../../types/manga-book.type'
import MangaPictureProcessing from './MangaPictureProcessing'
import { useState } from 'react'

type Props = {
  isMobile?: boolean
  imageUrl?: string
  description?: string
  onItemClick?: () => void
  item?: MangaBookType
  onCheckBoxImage?: any
  isMangaProcessing?: boolean
}

const MangaPictureItem = (props: Props) => {
  const { item, onCheckBoxImage, isMangaProcessing = false } = props

  const processedTextWithLineBreaks = item?.content?.split('。').join('。\n')

  return (
    <div className='flex cursor-pointer mr-7 ml-7 mb-[30px]'>
      <div
        onClick={item?.image?.url?.length ? props.onItemClick : undefined}
        className='manga-text-box whitespace-pre-line mr-2 line-clamp-[6] w-[130px] h-[130px]  '
        // style={{
        //   writingMode: 'vertical-lr',
        //   WebkitWritingMode: 'vertical-lr',
        //   WebkitLineClamp: 6,
        //   display: '-webkit-box'
        // }}
      >
        {processedTextWithLineBreaks}
      </div>
      {item?.image?.url && item?.image?.url?.length > 0 ? (
        <>
          {item?.image?.url?.map((imageUrl: UrlType) => (
            <Flex className='mr-2'>
              <Flex vertical justify='center' align='center'>
                <img
                  onClick={item?.image?.url ? props.onItemClick : undefined}
                  src={imageUrl?.url}
                  className=' h-[130px] w-[130px] mb-2 '
                />
                {!isMangaProcessing && (
                  <Checkbox
                    checked={imageUrl?.is_selected}
                    onClick={() => onCheckBoxImage(imageUrl)}
                  />
                )}
              </Flex>
            </Flex>
          ))}
        </>
      ) : (
        <Flex>
          <MangaPictureProcessing />
          <MangaPictureProcessing />
        </Flex>
      )}
    </div>
  )
}

export default MangaPictureItem
