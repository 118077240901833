import { useContext, useEffect, useRef } from 'react'
import { Flex, Input, Button, Col, Row, Typography, Select } from 'antd'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'

import useMangaGenerate from '../../hooks/useMangaGenerate'
import { AppContext } from '../../hooks/AppContext'
import MangaContent from './components/MangaContent'
import MangaPictureItem from './components/MangaPictureItem'
import MangaReadingModal from '../MangaReadingModal'
import useMangaReading from '../../hooks/useMangaReadingHook'
import MyDocumentPDF from './components/MyDocumentPDF'
import {
  MangaBookType,
  JapaneseType,
  MangaInforType,
  GenderType,
  MangaOverviewType
} from '../../types/manga-book.type'

const GenerateMangaScreen = () => {
  const { isMobile, setMenuSelected } = useContext(AppContext)

  const {
    hideMangaReadingModal,
    isShowMangaReadingModal,
    showMangaReadingModal,
    selectedMangaBookData,
    selectCount
  } = useMangaReading()

  const {
    checkIsDisabled,
    setMainName,
    mainAge,
    mainAgeSelected,
    handleSelectOld,
    mainGender,
    mainGenderSelected,
    handleSelectGender,
    setContentInput,
    japaneseType,
    japaneseTypeSelected,
    handleSelectJPtype,
    supportCharacter,
    supportCharacterSelected,
    isKeepChecking,
    isMangaProcessingJob,
    handleSelectSupport,
    handleRequestMangaImg,
    getPagesProcessing,
    mangaList,
    handleCreateContentManga,
    setIsMangaProcessingJob,
    contentList,
    mangaOverview,
    onCheckBoxImage,
    setEducationInput,
    validationErrors
  } = useMangaGenerate()

  useEffect(() => {
    if (isMangaProcessingJob) {
      const timerId = setInterval(() => {
        getPagesProcessing()
      }, 3000)

      if (!isKeepChecking) {
        setIsMangaProcessingJob(false)
        clearInterval(timerId)
      }

      return () => {
        clearInterval(timerId)
      }
    }
  }, [isMangaProcessingJob, isKeepChecking, mangaList])
  
  useEffect(() => {
    setMenuSelected(1)
  }, [])

  const generatePdfDocument = async () => {
    const blob = await pdf(
      <MyDocumentPDF
        mangaList={mangaList as MangaInforType}
        mangaOverview={mangaOverview as MangaOverviewType}
      />
    ).toBlob()
    saveAs(blob, mangaOverview?.title)
  }

  return (
    <Flex vertical>
      <h1 className='title'>絵本生成</h1>
      <Flex>
        <Col className='mt-5' style={{ marginLeft: isMobile ? 0 : 40 }}>
          <p className='font-bold mb-[10px]'>ユーザーの入力情報</p>
          <Row className='items-center w-full mb-[10px]'>
            <Typography className='w-[150px]'>年齢</Typography>
            <Select
              className='min-w-52 ml-3 h-9'
              value={`${mainAgeSelected}歳`}
              disabled={checkIsDisabled()}
              onChange={handleSelectOld}
            >
              {mainAge?.map((item: string) => (
                <Select.Option key={item} value={item}>
                  <p>{`${item}歳`}</p>
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row className='items-center w-full mb-[10px]'>
            <Typography className='w-[150px]'>名前</Typography>
            <Flex vertical>
              <Input
                className='w-52 ml-3 h-9'
                placeholder='ダイヤ'
                allowClear
                onChange={(e: any) => setMainName(e.target?.value)}
                disabled={checkIsDisabled()}
              />
              <Typography className='text-[12px] ml-3 text-red-600'>
                {validationErrors?.name?.message}
              </Typography>
            </Flex>
          </Row>
          <Row className='items-center w-full mb-[10px]'>
            <Typography className='w-[150px]'>性別</Typography>
            <Select
              className='min-w-52 ml-3 h-9'
              value={mainGenderSelected}
              disabled={checkIsDisabled()}
              onChange={handleSelectGender}
            >
              {mainGender?.map((item: GenderType) => (
                <Select.Option
                  key={item.value}
                  value={item?.value}
                  text={item?.text}
                >
                  <p>{item?.text}</p>
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row className='items-start w-full mb-[10px]'>
            <Typography className='w-[150px]'>興味</Typography>
            <Flex vertical>
              <Input.TextArea
                className='w-52 ml-3 h-16 resize-none'
                style={{ resize: 'none' }}
                placeholder='魚'
                allowClear
                onChange={(e: any) => setContentInput(e.target?.value)}
                disabled={checkIsDisabled()}
              />
              <Typography className='text-[12px] ml-3 text-red-600'>
                {validationErrors?.interest?.message}
              </Typography>
            </Flex>
          </Row>
          <Row className='items-center w-full mb-[10px]'>
            <Typography className='w-[150px]'>出力言語</Typography>
            <Select
              className='min-w-52 ml-3 h-9'
              value={japaneseTypeSelected?.name}
              disabled={checkIsDisabled()}
              onChange={handleSelectJPtype}
            >
              {japaneseType?.map((item: JapaneseType) => (
                <Select.Option
                  key={item?.type}
                  value={item?.name}
                  type={item?.type}
                >
                  <p>{item?.name}</p>
                </Select.Option>
              ))}
            </Select>
          </Row>
          <Row className='items-center w-full mb-[10px]'>
            <Typography className='w-[150px]'>教育的要素</Typography>
            <Flex vertical>
              <Input
                className='w-52 ml-3 h-9'
                placeholder='妹に優しく'
                allowClear
                onChange={(e: any) => setEducationInput(e.target?.value)}
                disabled={checkIsDisabled()}
              />
              <Typography className='text-[12px] ml-3 text-red-600'>
                {validationErrors?.educationInput?.message}
              </Typography>
            </Flex>
          </Row>
          <Row className='items-center w-full mb-[10px]'>
            <Typography className='w-[150px]'>
              一緒に登場して欲しいキャラクター
            </Typography>
            <div
              className='w-52 ml-3 h-9  border  border-solid rounded-[5px] border-[#d9d9d9] flex items-center pl-2'
              style={{
                backgroundColor: checkIsDisabled() ? '#f5f5f5 ' : '#ffffff',
                color: checkIsDisabled() ? '#bdbdbd' : 'black'
              }}
            >
              キャラクター＆男の子
            </div>
            {/* <Select
              className='min-w-52 ml-3 h-9'
              value={supportCharacterSelected}
              disabled={checkIsDisabled()}
              onChange={handleSelectSupport}
            >
              {supportCharacter?.map((item: string) => (
                <Select.Option key={item} value={item}>
                  <p>{item}</p>
                </Select.Option>
              ))}
            </Select> */}
          </Row>
          <Flex className='my-6 ' justify='flex-end'>
            <Button
              className='font-bold w-[210px]'
              type='primary'
              onClick={handleCreateContentManga}
              disabled={checkIsDisabled()}
            >
              ストーリーの文章を生成する
            </Button>
          </Flex>
        </Col>
      </Flex>

      {(contentList?.length > 0 ||
        (mangaList?.pages && mangaList?.pages?.length > 0)) && (
        <div>
          <h1 className='text-[16px] font-bold mb-2'>ストーリーの文章</h1>
          <div className='ml-3'>
            <Typography className='flex'>
              <strong>タイトル：</strong>
              {mangaOverview?.title}
            </Typography>
            <Typography>
              <strong>概要：</strong>
              {mangaOverview?.overview}
            </Typography>
          </div>
          <Flex justify='center' className='mb-4'>
            <Button
              className='bg-[#ED991B] text-[#ffffff] font-bold w-[210px] mt-4  '
              onClick={handleRequestMangaImg}
              disabled={checkIsDisabled()}
            >
              ストーリー画像を生成する
            </Button>
          </Flex>
          {contentList?.length > 0 && (
            <MangaContent
              contentList={contentList}
              handleRequestMangaImg={handleRequestMangaImg}
            />
          )}
          {mangaList && mangaList?.pages?.length > 0 && (
            <Flex vertical>
              <div className='flex justify-center'>
                <Button
                  className='font-bold w-[210px] h-[31px] bg-[#03B048] hover:bg-[#03B048] text-white'
                  onClick={generatePdfDocument}
                  // disabled={checkDisableDownloadPdf()}
                >
                  ダウンロード
                </Button>
              </div>

              <Flex className='flex-wrap mt-9'>
                {mangaList?.pages?.map((item: MangaBookType) => (
                  <MangaPictureItem
                    item={item}
                    key={item?.id}
                    onItemClick={() => showMangaReadingModal(item)}
                    onCheckBoxImage={onCheckBoxImage}
                  />
                ))}
              </Flex>
            </Flex>
          )}
        </div>
      )}

      <MangaReadingModal
        isShowModal={isShowMangaReadingModal}
        onCancel={hideMangaReadingModal}
        data={mangaList as MangaInforType}
        initialMangaBookData={selectedMangaBookData}
        selectCount={selectCount}
      />
    </Flex>
  )
}

export default GenerateMangaScreen
