import { Spin } from 'antd'
import { RouterProvider } from 'react-router-dom'
import { useContext } from 'react'

import './App.css'
import { AppContext } from './hooks/AppContext'
import router from './router'

function App() {
  const { isSpinning, contextHolder } = useContext(AppContext)

  return (
    <Spin spinning={isSpinning} size='large' className='spinner'>
      <div style={{ height: window.innerHeight }}>
        {contextHolder}
        <RouterProvider router={router} />
      </div>
    </Spin>
  )
}

export default App
