import { useCallback, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { AppContext } from './AppContext'
import CommonAPIs from '../controller/API/CommonAPIs'
import {
  ContentMangaType,
  GenderType,
  JapaneseType,
  MangaInforType,
  MangaInputInforType,
  MangaOverviewType,
  UrlType
} from '../types/manga-book.type'
import MangaDummyList from '../dummy/comic-list.json'
import { isMangaValidationInfo } from '../validations/MangaValidation'

const MainAgeSelect = ['7', '8', '9', '10', '11', '12']
const GenderSelect = [
  {
    value: 1,
    text: '男'
  },
  {
    value: 2,
    text: '女'
  }
]
const JapaneseTypeSelect = [
  { type: 1, name: 'ひらがなのみ' },
  { type: 2, name: 'ひらがなとカタカナのみ' },
  { type: 3, name: '漢字' }
]

const useMangaGenerate = () => {
  const {
    accessToken,
    showErrorAlert,
    showWaitGenerate,
    setIsSpinning,
    showGenerateCompleted,
    showFailure
  } = useContext(AppContext)

  const [mainName, setMainName] = useState<string>('')
  const [mainAge, setMainAge] = useState<string[]>(MainAgeSelect)
  const [mainAgeSelected, setMainAgeSelected] = useState<string | number>(MainAgeSelect[0])
  const [mainGender, setMainGender] = useState<GenderType[]>(GenderSelect)
  const [mainGenderSelected, setMainGenderSelected] = useState<GenderType>(GenderSelect[0])
  const [contentInput, setContentInput] = useState<string>('')
  const [japaneseType, setJapaneseType] = useState<JapaneseType[]>(JapaneseTypeSelect)
  const [japaneseTypeSelected, setJapaneseTypeSelected] = useState<JapaneseType>(JapaneseTypeSelect[0])
  const [supportCharacter, setSupportCharacter] = useState<string[]>([])
  const [supportCharacterSelected, setSupportCharacterSelected] = useState<string>(supportCharacter[0])

  const [isMangaProcessingJob, setIsMangaProcessingJob] = useState<boolean>(false)
  const [isKeepChecking, setIsKeepChecking] = useState<boolean>(true)
  const [mangaList, setMangaList] = useState<MangaInforType | null>(null)
  const [contentList, setContentList] = useState<ContentMangaType[]>([])
  const [mangaID, setMangaID] = useState<number | string>('')
  const [mangaOverview, setMangaOverview] = useState<MangaOverviewType>()
  const [educationInput, setEducationInput] = useState<string>('')
  const [validationErrors, setValidationErrors] = useState<any>()

  const checkIsDisabled = (): boolean => {
    if (isMangaProcessingJob) {
      return true
    }
    return false
  }

  const onChange = () => {
    return null
  }

  const handleSelectOld = (value: string) => {
    setMainAgeSelected(value)
  }

  const handleSelectGender = (value: any, selected: any) => {
    setMainGenderSelected({
      value: selected?.value,
      text: selected?.text
    })
  }

  const handleSelectJPtype = (value: any, selected: any) => {
    setJapaneseTypeSelected({
      name: selected?.value,
      type: selected?.type
    })
  }

  const handleSelectSupport = (value: string) => {
    setSupportCharacterSelected(value)
  }

  const isValidationInfo = async (data: MangaInputInforType) => {
    try {
      const validData = await isMangaValidationInfo(data)
      setValidationErrors(null)
      return validData
    } catch (error) {
      setValidationErrors(error)
      return false
    }
  }

  const handleCreateContentManga = async () => {
    const inputDt: MangaInputInforType = {
      age: mainAgeSelected,
      gender: mainGenderSelected?.value,
      name: mainName,
      interest: contentInput,
      outputLanguage: japaneseTypeSelected?.type,
      educationInput: educationInput,
      accompanyingCharacter: 'キャラクター＆男の子'
    }

    let validRes = await isValidationInfo(inputDt)
    if (!validRes) {
      return
    }

    setIsSpinning(true)
    setMangaList(null)
    showWaitGenerate('ストーリーの文章を生成しています。AIサーバー状況によって生成時間かかる場合あります。後ほどご確認ください。')

    CommonAPIs.createContentManga(accessToken, inputDt)
      .then((res) => {
        showGenerateCompleted()
        setContentList(res?.pages)
        setMangaID(res?.id)
        setMangaOverview({
          title: res?.title,
          overview: res?.overview
        })
      })
      .catch((err) => {
        showErrorAlert(err)
        showFailure('ストーリーの文章の生成が失敗しました。再度生成して みてください。')
      })
      .finally(() => setIsSpinning(false))
  }

  const handleRequestMangaImg = async () => {
    setIsKeepChecking(true)
    setMangaList(null)
    showWaitGenerate()
    await CommonAPIs.requestRenderMangaImg(accessToken, mangaID)
      .then(() => {
        setContentList([])
        setIsMangaProcessingJob(true)
      })
      .catch((err) => {
        showErrorAlert(err)
        showFailure('ストーリー画像の生成が失敗しました。再度生成して みてください。')
      })
  }

  const getPagesProcessing = async () => {
    await CommonAPIs.checkStatusMangaJob(accessToken, mangaID)
      .then((res) => {
        console.log('res::', res)
        setIsKeepChecking(res?.keep_checking)
        if (!res.keep_checking) {
          showGenerateCompleted('ストーリー画像を生成しました。ご確認ください。')
        }
        const newDt = {
          ...res,
          pages: res?.pages?.map((item: any, index: number) => {
            if (mangaList && mangaList?.pages[index]?.image?.url?.length > 0) {
              return mangaList?.pages[index]
            }
            item.image = {
              id: item?.image?.id,
              url: (item?.image?.url ?? [])?.map((items: string, index: number) => {
                return {
                  id: uuidv4(),
                  url: items ?? [],
                  is_selected: index == 0 ? true : false
                }
              })
            }
            return item
          })
        }

        setMangaList(newDt)
      })
      .catch((err) => showErrorAlert(err))
  }

  const onCheckBoxImage = (url: any) => {
    const newDt = {
      ...mangaList,
      pages: mangaList?.pages?.map((item: any) => {
        item.image = {
          id: item.image.id,
          url: item.image.url?.map((items: UrlType) => {
            return {
              id: uuidv4(),
              url: items?.url,
              is_selected: items?.id == url?.id ? !items?.is_selected : items?.is_selected
            }
          })
        }
        return item
      })
    } as MangaInforType
    console.log('newDt::', newDt)
    setMangaList(newDt)
  }

  const getCheckpointList = () => {
    CommonAPIs.getCheckpointList(accessToken)
      .then((res) => {
        const newDt = res?.data?.map((obj: any) => obj.name)
        setSupportCharacter(newDt)
        setSupportCharacterSelected(newDt[0])
      })
      .catch((err) => showErrorAlert(err))
  }

  const checkDisableDownloadPdf = (): boolean => {
    if (mangaList && mangaList?.pages[0]?.image?.url.length <= 0) {
      return true
    }
    return false
  }

  return {
    accessToken,
    checkIsDisabled,
    onChange,
    mainName,
    setMainName,
    mainAge,
    mainAgeSelected,
    handleSelectOld,
    mainGender,
    mainGenderSelected,
    handleSelectGender,
    contentInput,
    setContentInput,
    japaneseType,
    japaneseTypeSelected,
    handleSelectJPtype,
    supportCharacter,
    supportCharacterSelected,
    handleSelectSupport,
    handleRequestMangaImg,
    isMangaProcessingJob,
    getPagesProcessing,
    isKeepChecking,
    mangaList,
    handleCreateContentManga,
    setIsMangaProcessingJob,
    getCheckpointList,
    contentList,
    mangaOverview,
    onCheckBoxImage,
    educationInput,
    setEducationInput,
    checkDisableDownloadPdf,
    validationErrors
  }
}

export default useMangaGenerate
