import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Row,
  DatePicker,
  TimeRangePickerProps,
  ConfigProvider,
  Typography
} from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import ja from 'antd/locale/ja_JP'
import moment from 'moment'

type Props = {
  setMediaList?: any
  setMediaFilteredList?: any
  schedule?: any
  setSchedule?: any
  imgTypeSelected?: any
  setImgTypeSelected?: any
  handleFilter?: () => void
}

const { RangePicker }: any = DatePicker

const dateFormat = 'YYYY/MM/DD'

const img_type = [
  {
    type: 0,
    name: 'すべて種類'
  },
  {
    type: 3,
    name: 'テキストで生成'
  },
  {
    type: 4,
    name: '画像アップで生成'
  }
]

const MediaFilter = (props: Props) => {
  const {
    setMediaFilteredList,
    schedule,
    setSchedule,
    imgTypeSelected,
    setImgTypeSelected,
    handleFilter
  } = props

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setSchedule({
        startTime: new Date(dateStrings[0]),
        endTime: new Date(dateStrings[1])
      })
    }
  }

  return (
    <Col>
      <Row
        className='h-[130px] w-auto justify-between items-center mt-10 border-solid border-[1px] pl-10 pr-16 '
        style={{
          borderColor: '#b2b2b2',
          borderRadius: 5
        }}
      >
        <Row className='flex items-center justify-center'>
          <Typography className='mr-6 font-bold'>絵本生成日時</Typography>
          <Row>
            <ConfigProvider locale={ja}>
              <RangePicker
                value={[dayjs(schedule.startTime), dayjs(schedule.endTime)]}
                // presets={rangePresets}
                onChange={onRangeChange}
                format={dateFormat}
                showToday={true}
              />
            </ConfigProvider>
          </Row>
        </Row>
        <Row>
          <Button
            className='w-[100px] font-bold'
            onClick={handleFilter}
            type='primary'
          >
            検索する
          </Button>
        </Row>
      </Row>
    </Col>
  )
}

export default MediaFilter
