import { createContext, useEffect, useState } from 'react'
import { notification } from 'antd'
import type { NotificationPlacement } from 'antd/es/notification/interface'
import Constant from '../controller/Constant'
import { CheckCircleOutlined } from '@ant-design/icons'
interface contextProps {
  isSpinning?: boolean
  setIsSpinning?: any
  menuSelected?: number
  setMenuSelected?: any
  showErrorAlert?: any
  contextHolder?: any
  isMobile?: boolean
  showWaitGenerate?: any
  showGenerateCompleted?: any
  accessToken?: any
  showFailure?: any
}

export const AppContext = createContext({} as contextProps)

export const AppProvider = ({ children }: any) => {
  const [isSpinning, setIsSpinning] = useState<boolean>(false)
  const [menuSelected, setMenuSelected] = useState<number>()
  const [api, contextHolder] = notification.useNotification()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const accessToken = localStorage.getItem('token') ?? null

  const showErrorAlert = (
    errorMsg: any,
    placement: NotificationPlacement = 'topRight'
  ) => {
    api.error({
      message: (
        <AppContext.Consumer>
          {() => `${errorMsg?.response?.data?.message ?? errorMsg?.message}`}
        </AppContext.Consumer>
      ),
      placement
    })
  }

  const showFailure = (
    errorMsg: any,
    placement: NotificationPlacement = 'topRight'
  ) => {
    api.error({
      message: (
        <AppContext.Consumer>
          {() => `${errorMsg}`}
        </AppContext.Consumer>
      ),
      placement
    })
  }

  const showWaitGenerate = (
    text = 'ストーリー画像を生成しています。AIサーバー状況によって生成時間かかる場合あります。後ほどご確認ください。',
    placement: NotificationPlacement = 'top'
  ) => {
    notification.open({
      message: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleOutlined
            style={{ fontSize: 30, color: '#2CD003', marginRight: 20 }}
          />
          <div>{`${text}`}</div>
        </div>
      ),
      placement,
      style: {
        whiteSpace: 'pre-line',
        zIndex: 999,
        width: 520,
        paddingTop: 10,
        paddingBottom: 0
      },
      closeIcon: false
    })
  }

  const showGenerateCompleted = (
    text = 'ストーリーの文章を生成しました。ご確認ください。',
    placement: NotificationPlacement = 'top'
  ) => {
    notification.open({
      message: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleOutlined
            style={{ fontSize: 30, color: '#2CD003', marginRight: 20 }}
          />
          <div>{text}</div>
        </div>
      ),
      placement,
      style: {
        whiteSpace: 'pre-line',
        zIndex: 999,
        width: 520,
        paddingTop: 10,
        paddingBottom: 0
      },
      closeIcon: false
    })
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setIsMobile(windowWidth <= 750)
  }, [windowWidth])

  return (
    <AppContext.Provider
      value={{
        isSpinning,
        setIsSpinning,
        menuSelected,
        setMenuSelected,
        showErrorAlert,
        contextHolder,
        isMobile,
        showWaitGenerate,
        showGenerateCompleted,
        showFailure,
        accessToken
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
