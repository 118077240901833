import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'

type Props = {}

const MangaPictureProcessing = (props: Props) => {
  return (
    <div className=' bg-[#D9D9D9] flex items-center justify-center w-[130px] h-[130px] mr-2'>
      <Spin
        spinning={true}
        indicator={<LoadingOutlined className='size-[24px]' />}
      />
    </div>
  )
}

export default MangaPictureProcessing
