import { useContext, useState } from 'react'
import { AppContext } from './AppContext'
import CommonAPIs from '../controller/API/CommonAPIs'

import CMlist from '../dummy/comic-list.json'
import { MangaInforType, UrlType } from '../types/manga-book.type'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

const useMangaHistory = () => {
  const { accessToken, showErrorAlert, setIsSpinning } = useContext(AppContext)
  const [schedule, setSchedule] = useState<{
    startTime: Date | string
    endTime: Date | string
  }>({
    startTime: moment().subtract(6, 'days').toDate(),
    endTime: new Date()
  })
  const [imgTypeSelected, setImgTypeSelected] = useState({
    type: 0,
    name: 'すべて種類'
  })
  const [mangaHistoryList, setMangaHistoryList] = useState<MangaInforType[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [mangaSelectIndex, setMangaSelectIndex] = useState<number>(0)
  const [mangaProcessingList, setMangaProcessingList] = useState<MangaInforType[]>([])
  const [mangaProcessingSelectedList, setMangaProcessingSelectedList] = useState<MangaInforType[]>([])
  const [isLoadingList, setIsLoadingList] = useState<boolean>(false)

  const getMangaHistoryList = (page = 1) => {
    setIsSpinning(true)
    setIsLoadingList(true)
    CommonAPIs.getMangaHistory(accessToken, schedule.startTime, schedule.endTime, page)
      .then((res) => {
        console.log('res::', res)
        window.scrollTo(0, 0);
        if (mangaHistoryList?.length > 0) {
          const modifiedArray = [...mangaHistoryList]
          const responseDt = res?.data[0]
          const newValue = {
            ...responseDt,
            pages: responseDt?.pages?.map((item: any) => {
              item.image = {
                id: item?.image?.id,
                url: (item?.image?.url ?? [])?.map((items: any, index: number) => {
                  return {
                    id: uuidv4(),
                    url: items ?? [],
                    is_selected: index == 0 ? true : false
                  }
                })
              }
              return item
            })
          }
          setMangaHistoryList([newValue, ...modifiedArray])
        } else {
          const configData = res?.data?.map((data: any) => {
            data?.pages?.map((item: any) => {
              item.image = {
                id: item?.image?.id,
                url: (item?.image?.url ?? [])?.map((items: any, index: number) => {
                  return {
                    id: uuidv4(),
                    url: items ?? [],
                    is_selected: index == 0 ? true : false
                  }
                })
              }
            })
            return data
          })
          setMangaHistoryList(configData)
        }

        setTotalPages(res?.meta.last_page)
      })
      .catch((err) => showErrorAlert(err))
      .finally(() => {
        setIsSpinning(false)
        setIsLoadingList(false)
      })
  }

  const getMangaProcessing = () => {
    const mangaProcessingStorageAmount = localStorage.getItem('mangaProcessingAmount') ?? 0
    CommonAPIs.getMangaProcessing(accessToken)
      .then((res) => {
        if (mangaProcessingStorageAmount && Number(mangaProcessingStorageAmount) > res?.length) {
          getMangaHistoryList()
        }

        setMangaProcessingList(res)

        let newDt = res.filter((item: MangaInforType) => {
          const createdAt = moment(item.updated_at)
          const startDate = moment(schedule.startTime)
          const endDate = moment(schedule.endTime)
          return createdAt.isBetween(startDate, endDate, 'days', '[]')
        })
        const configData = newDt?.map((data: any) => {
          data?.pages?.map((item: any) => {
            item.image = {
              id: item?.image?.id,
              url: (item?.image?.url ?? [])?.map((items: any, index: number) => {
                return {
                  id: uuidv4(),
                  url: items ?? [],
                  is_selected: index == 0 ? true : false
                }
              })
            }
          })
          return data
        })

        setMangaProcessingSelectedList(configData)
        localStorage.setItem('mangaProcessingAmount', res?.length)
      })
      .catch((err) => showErrorAlert(err))
  }

  const onChangePage = (page: any) => {
    setCurrentPage(page)
  }

  const getMangaHistoryListWhenFilter = (page = 1) => {
    setIsSpinning(true)
    setIsLoadingList(true)
    CommonAPIs.getMangaHistory(accessToken, schedule.startTime, schedule.endTime, page)
      .then((res) => {
        const configData = res?.data?.map((data: any) => {
          data?.pages?.map((item: any) => {
            item.image = {
              id: item?.image?.id,
              url: (item?.image?.url ?? [])?.map((items: any, index: number) => {
                return {
                  id: uuidv4(),
                  url: items ?? [],
                  is_selected: index == 0 ? true : false
                }
              })
            }
          })
          return data
        })
        setMangaHistoryList(configData)
        setTotalPages(res?.meta.last_page)
      })
      .catch((err) => showErrorAlert(err))
      .finally(() => {
        setIsSpinning(false)
        setIsLoadingList(false)
      })
  }

  const handleFilter = () => {
    getMangaHistoryListWhenFilter()
    let newDt = mangaProcessingList.filter((item: MangaInforType) => {
      const createdAt = moment(item.updated_at)
      const startDate = moment(schedule.startTime)
      const endDate = moment(schedule.endTime)

      return createdAt.isBetween(startDate, endDate, 'days', '[]')
    })
    setMangaProcessingSelectedList(newDt)
  }

  const onCheckBoxImage = (url: any) => {
    const newDt = mangaHistoryList?.map((item) => {
      item?.pages?.map((item: any) => {
        item.image = {
          id: item.image.id,
          url: item.image.url?.map((items: UrlType) => {
            return {
              id: uuidv4(),
              url: items?.url,
              is_selected: items?.id == url?.id ? !items?.is_selected : items?.is_selected
            }
          })
        }
      })
      return item
    })
    setMangaHistoryList(newDt)
  }

  return {
    schedule,
    setSchedule,
    imgTypeSelected,
    setImgTypeSelected,
    getMangaHistoryList,
    mangaHistoryList,
    onChangePage,
    currentPage,
    totalPages,
    handleFilter,
    setMangaSelectIndex,
    mangaSelectIndex,
    getMangaProcessing,
    mangaProcessingList,
    mangaProcessingSelectedList,
    isLoadingList,
    onCheckBoxImage
  }
}

export default useMangaHistory
