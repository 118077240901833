import axios from 'axios'
import { MangaInputInforType } from '../../types/manga-book.type'
import moment from 'moment'

export default class CommonAPIs {
  static baseURL = 'https://api-ehon-dev.test-development.work'
  // static baseURL = 'https://snafty-staging.test-development.work'
  static access_token = localStorage.getItem('token')

  static endpoints = {
    login: this.baseURL + '/ai-img/login',
    generateImgFromDescription: this.baseURL + '/ai-img/creation/description',
    generateImgFromImg: this.baseURL + '/ai-img/creation/mask',
    getMedias: this.baseURL + '/ai-img/media-by-user',
    rerenderImgToImg: this.baseURL + '/ai-img/media-by-user',
    processingJob: this.baseURL + '/ai-img/proccessing-job',
    recreateMask: this.baseURL + '/ai-img/creation/mask-recreating',
    generateImg2ImgPersonAnime:
      this.baseURL + '/ai-img/creation/img2img-person-anime',
    mediaStatusJob: this.baseURL + '/ai-img/job-data',
    fixBackgroundImg2Img2:
      this.baseURL + '/ai-img/creation/img2img-fix-background',
    fixHandTxt2Img: this.baseURL + '/ai-img/creation/txt2img-fix-hand',
    renderMangaAiImg: this.baseURL + '/manga-api/manga',
    checkpoint: this.baseURL + '/ai-img/checkpoints',
    createContentManga: this.baseURL + '/manga-api/manga/manga-content',
    mangaHistory: this.baseURL + '/manga-api/manga/by-user',
    mangaProcessing: this.baseURL + '/manga-api/manga/processing-job'
  }

  static headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json'
  }

  static async login(email: string, password: string) {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    }
    try {
      const formData = new FormData()
      formData.append('email', email)
      formData.append('password', password)
      let response = await axios.post(this.endpoints.login, formData, {
        headers
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async generateImgFromDescription(
    descriptionList: any,
    checkPointSelected: string,
    token: string | null,
    isClothesTriggerWords: number
  ) {
    const access_token = token ?? this.access_token

    const headers = {
      ...this.headers,
      Authorization: 'Bearer ' + access_token
    }

    const formData = new FormData()
    for (var i = 0; i < descriptionList?.length; i++) {
      console.log(`descriptionList[${i}].des::`, descriptionList[i].des)
      formData.append(`descriptions[${i}]`, descriptionList[i].des)
    }

    formData.append('checkpoint', checkPointSelected)
    formData.append('is_clothe', '1')
    formData.append('isClothesTriggerWords', `${isClothesTriggerWords}`)

    try {
      let response = await axios.post(
        CommonAPIs.endpoints.generateImgFromDescription,
        formData,
        {
          headers: headers
        }
      )
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async generateImgFromImg(
    token: string | null,
    imageCropUrl: any,
    checkPointSelected: string,
    clothesTypeSelected: number,
    descriptionClothes: string
  ) {
    const access_token = token ?? this.access_token

    console.log('access_token::', access_token)
    console.log('imageCropUrl::', imageCropUrl)
    console.log('checkPointSelected::', checkPointSelected)
    console.log('clothesTypeSelected::', clothesTypeSelected)
    console.log('descriptionClothes::', descriptionClothes)

    const formData = new FormData()
    formData.append('image', imageCropUrl)
    formData.append('checkpoint', checkPointSelected)
    formData.append('clothes_type', String(clothesTypeSelected))
    if (descriptionClothes) {
      formData.append('description', descriptionClothes)
    }

    try {
      let response = await axios.post(
        CommonAPIs.endpoints.generateImgFromImg,
        formData,
        {
          headers: {
            ...this.headers,
            Authorization: 'Bearer ' + access_token
          }
        }
      )
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getCheckpointList(token: string | null) {
    const access_token = token ?? this.access_token
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${access_token}`
    }
    try {
      let response = await axios.get(CommonAPIs.endpoints.checkpoint, {
        headers
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getMedias(token: string | null) {
    const access_token = token ?? this.access_token
    try {
      let response = await axios.get(CommonAPIs.endpoints.getMedias, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getMediasProcessing(token: string | null) {
    const access_token = token ?? this.access_token
    console.log('Access token::', access_token)
    try {
      let response = await axios.get(CommonAPIs.endpoints.processingJob, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async rerenderMask(
    token: string | null,
    paintImg: any,
    mediaID: number | string
  ) {
    const access_token = token ?? this.access_token

    const formData = new FormData()
    formData.append('mask_url', paintImg)

    try {
      const url = `${CommonAPIs.endpoints.recreateMask}/${mediaID}`
      let response = await axios.post(url, formData, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async generateImgFromImgPersonAnime(
    token: string | null,
    imageCropUrl: any,
    checkPointSelected: string,
    clothesTypeSelected: number,
    descriptionClothes: string
  ) {
    const access_token = token ?? this.access_token

    console.log('access_token::', access_token)
    console.log('imageCropUrl::', imageCropUrl)
    console.log('checkPointSelected::', checkPointSelected)
    console.log('clothesTypeSelected::', clothesTypeSelected)
    console.log('descriptionClothes::', descriptionClothes)

    const formData = new FormData()
    formData.append('image', imageCropUrl)
    formData.append('checkpoint', checkPointSelected)
    formData.append('clothes_type', String(clothesTypeSelected))
    if (descriptionClothes) {
      formData.append('description', descriptionClothes)
    }

    try {
      let response = await axios.post(
        CommonAPIs.endpoints.generateImg2ImgPersonAnime,
        formData,
        {
          headers: {
            ...this.headers,
            Authorization: 'Bearer ' + access_token
          }
        }
      )
      return Promise.resolve(response.data?.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async checkStatusMediaJob(token: string | null, jobID: number) {
    const access_token = token ?? this.access_token
    try {
      const url = `${CommonAPIs.endpoints.mediaStatusJob}/${jobID}/status`
      let response = await axios.get(url, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async fixBackgroundImg2Img(
    token: string | null,
    paintImg: any,
    mediaID: number | string
  ) {
    const access_token = token ?? this.access_token

    const formData = new FormData()
    formData.append('mask_url', paintImg)

    try {
      const url = `${CommonAPIs.endpoints.fixBackgroundImg2Img2}/${mediaID}`
      let response = await axios.post(url, formData, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async fixHandTxt2Img(
    token: string | null,
    paintImg: any,
    mediaID: number | string
  ) {
    const access_token = token ?? this.access_token

    const formData = new FormData()
    formData.append('mask_url', paintImg)

    try {
      const url = `${CommonAPIs.endpoints.fixHandTxt2Img}/${mediaID}`
      console.log(url)
      let response = await axios.post(url, formData, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + access_token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async requestRenderMangaImg(
    accessToken: string | null,
    mangaID: number | string
  ) {
    const token = accessToken ?? this.access_token
    try {
      const url = `${CommonAPIs.endpoints.renderMangaAiImg}/${mangaID}/image-rendering`
      console.log(url)
      let response = await axios.get(url, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async checkStatusMangaJob(
    accessToken: string | null,
    mangaID: number | string
  ) {
    const token = accessToken ?? this.access_token

    try {
      const url = `${CommonAPIs.endpoints.renderMangaAiImg}/${mangaID}/status`
      console.log(url)
      let response = await axios.get(url, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async createContentManga(
    accessToken: string,
    inputDt: MangaInputInforType
  ) {
    const token = accessToken ?? this.access_token

    console.log('inputDt::', inputDt)
    const formData = new FormData()
    formData.append('age', inputDt?.age.toString())
    formData.append('gender', inputDt?.gender.toString())
    formData.append('name', inputDt?.name)
    formData.append('interest', inputDt?.interest)
    formData.append('output_language', inputDt?.outputLanguage.toString())
    formData.append('educational_factors', inputDt?.educationInput)
    formData.append('checkpoint', inputDt?.accompanyingCharacter)
    console.log('url::', CommonAPIs.endpoints.createContentManga)

    try {
      let response = await axios.post(
        CommonAPIs.endpoints.createContentManga,
        formData,
        {
          headers: {
            ...this.headers,
            Authorization: 'Bearer ' + token
          }
        }
      )
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }

  static async getMangaHistory(
    accessToken: string | null,
    startDate: string | Date,
    endDate: string | Date,
    page: any
  ) {
    const token = accessToken ?? this.access_token

    const startD = moment(startDate).format('YYYY-MM-DD')
    const endD = moment(endDate).format('YYYY-MM-DD')

    try {
      const url = `${CommonAPIs.endpoints.mangaHistory}?start_date=${startD}&end_date=${endD}&page=${page}`
      let response = await axios.get(url, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }
  static async getMangaProcessing(accessToken: string | null) {
    const token = accessToken ?? this.access_token

    try {
      let response = await axios.get(CommonAPIs.endpoints.mangaProcessing, {
        headers: {
          ...this.headers,
          Authorization: 'Bearer ' + token
        }
      })
      return Promise.resolve(response.data?.data ?? [])
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
