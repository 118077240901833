import React, { useEffect, useRef, useState } from 'react'
import MangaReadingCard from './MangaReadingCard'
import { Carousel, Radio, Modal, Button, Space, Flex } from 'antd'
import type { CarouselProps, RadioChangeEvent } from 'antd'
import {
  BackwardFilled,
  LeftCircleFilled,
  RightCircleFilled
} from '@ant-design/icons'
import useMangaReading from '../hooks/useMangaReadingHook'

import type { CarouselRef } from 'antd/es/carousel'
import { MangaBookType, MangaInforType } from '../types/manga-book.type'

type Props = {
  isShowModal: boolean
  onCancel: () => void
  onOk?: () => void
  data: MangaInforType
  initialMangaBookData?: MangaBookType
  selectCount: number
}

const MangaReadingModal: React.FC<Props> = (props) => {
  const { onNextCarouselClick, onPreviouseCarouselClick, carouselRef } =
    useMangaReading()
  // let carouselRef = useRef<CarouselRef | null>();
  const mangaRenderedList = props.data?.pages?.filter((obj) => obj?.image?.url)

  useEffect(() => {
    carouselRef.current?.goTo(
      mangaRenderedList?.indexOf(
        props.initialMangaBookData ?? mangaRenderedList[0]
      )
    )
  }, [props.initialMangaBookData?.id, props?.selectCount])

  return (
    <Modal
      centered
      open={props.isShowModal}
      onOk={props.onOk}
      onCancel={props.onCancel}
      width={'85%'}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div style={{ padding: 20 }}>
        <Button
          icon={<LeftCircleFilled style={{ fontSize: 28 }} />}
          className='absolute top-[50%] left-0 border-0 ml-2'
          onClick={onPreviouseCarouselClick}
        />
        <Button
          icon={<RightCircleFilled style={{ fontSize: 28 }} />}
          className='absolute top-[50%] right-0 border-0 mr-2'
          onClick={onNextCarouselClick}
        />

        <div className='ml-4 mr-4'>
          <Carousel
            ref={(ref) => (carouselRef.current = ref)}
            dots={false}
            infinite={false}
            initialSlide={
              props.initialMangaBookData
                ? mangaRenderedList?.indexOf(props.initialMangaBookData)
                : 0
            }
            // waitForAnimate={true}
          >
            {mangaRenderedList?.map((item) => (
              <MangaReadingCard
                key={item.id}
                description={item?.content}
                imageUrl={item?.image?.url}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </Modal>
  )
}

export default MangaReadingModal
