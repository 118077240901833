import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer'
import font from '../../../fonts/Nasu-Regular.ttf'
import {
  MangaInforType,
  MangaOverviewType
} from '../../../types/manga-book.type'

Font.register({
  family: 'Nasu Regular',
  src: font
})

type Props = {
  mangaList: MangaInforType
  mangaOverview: MangaOverviewType
}

const MyDocumentPDF = (props: Props) => {
  const { mangaList, mangaOverview } = props

  const get_image = (imageUrl: string) => {
    return `${imageUrl}?${new Date().getTime()}`
  }

  const processedTextWithLineBreaks = (content: string) => {
    try {
      return content?.match(/.{1,10}/g)?.join('\n') ?? ''
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Document>
      <Page size='A4' style={styles.page} fixed orientation='landscape'>
        <View style={styles.section}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <View style={{ flex: 1 }}>
              <View>
                <Image
                  style={{
                    width: 450,
                    height: 450,
                    marginRight: 50
                  }}
                  source={get_image(
                    mangaList?.pages[0]?.image?.url[0]?.url as string
                  )}
                />
              </View>
            </View>
            <Text style={styles.japaneseText}>
              {processedTextWithLineBreaks(mangaOverview?.title)}
            </Text>
          </View>
        </View>
      </Page>
      {mangaList?.pages?.map((item: any) => (
        <Page size='A4' style={styles.page} fixed orientation='landscape'>
          <View style={styles.section}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <View style={{ flex: 1 }}>
                {item?.image?.url?.map((items: any) => {
                  const is2Img = () => {
                    return item?.image?.url.every(
                      (url: any) => url?.is_selected == true
                    )
                  }
                  return (
                    <View>
                      {items?.is_selected && (
                        <Image
                          style={{
                            width: is2Img() ? 250 : 450,
                            height: is2Img() ? 250 : 450,
                            marginRight: 50,
                            marginLeft: is2Img() ? 100 : 0
                          }}
                          source={get_image(items?.url)}
                        />
                      )}
                    </View>
                  )
                })}
              </View>
              <Text style={styles.japaneseText}>
                {processedTextWithLineBreaks(item?.content)}
              </Text>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    justifyContent: 'center'
  },
  japaneseText: {
    fontFamily: 'Nasu Regular', // Specify font family
    fontSize: 30,
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 50,
    textAlign: 'center',
    marginRight: 20
  }
})

export default MyDocumentPDF
