import { createBrowserRouter } from 'react-router-dom'
import BaseLayout from '../pages/layouts/BaseLayout'
import GenerateMangaScreen from '../components/Generate/GenerateMangaScreen'
import LoginScreen from '../components/Auth/LoginScreen'
import PhotoLibraryScreen from '../components/PhotoLibrary/PhotoLibraryScreen'
import ProfileScreen from '../components/Generate/ProfileScreen'

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginScreen />
  },

  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <GenerateMangaScreen />
      },
      {
        path: 'txt2img',
        element: <GenerateMangaScreen />
      },
      {
        path: 'photo-library',
        element: <PhotoLibraryScreen />
      },
      {
        path: 'profile',
        element: <ProfileScreen />
      }
    ]
  }
])

export default router
