import { Flex, Layout, Pagination, Typography } from 'antd'
import { useContext, useCallback, useEffect } from 'react'
import MediaFilter from './components/MediaFilter'
import MangaHistoryItem from './components/MangaHistoryItem'
import useMangaHistory from '../../hooks/useMangaHistory'
import { MangaInforType } from '../../types/manga-book.type'
import useMangaReading from '../../hooks/useMangaReadingHook'
import MangaReadingModal from '../MangaReadingModal'
import { AppContext } from '../../hooks/AppContext'

const PhotoLibraryScreen = () => {
  const { setMenuSelected } = useContext(AppContext)

  const {
    hideMangaReadingModal,
    isShowMangaReadingModal,
    showMangaReadingModal,
    selectedMangaBookData,
    selectCount
  } = useMangaReading()

  const {
    schedule,
    setSchedule,
    imgTypeSelected,
    setImgTypeSelected,
    getMangaHistoryList,
    mangaHistoryList,
    onChangePage,
    currentPage,
    totalPages,
    handleFilter,
    setMangaSelectIndex,
    mangaSelectIndex,
    getMangaProcessing,
    mangaProcessingList,
    mangaProcessingSelectedList,
    isLoadingList,
    onCheckBoxImage
  } = useMangaHistory()

  const checkSelectedDataShowModal = useCallback(() => {
    const newDt = mangaProcessingSelectedList?.map((item: any) => {
      return item?.pages?.map((items: any) => items?.id)
    })

    const isMangaExist = newDt?.some((item) => {
      return item.includes(selectedMangaBookData?.id)
    })

    return isMangaExist
  }, [selectedMangaBookData, mangaProcessingSelectedList])

  useEffect(() => {
    getMangaHistoryList(currentPage)
  }, [currentPage])

  useEffect(() => {
    getMangaProcessing()
    setMenuSelected(3)
  }, [])

  useEffect(() => {
    const timerId = setInterval(() => {
      getMangaProcessing()
    }, 3000)

    if (mangaProcessingList?.length <= 0) {
      clearInterval(timerId)
    }

    return () => {
      clearInterval(timerId)
    }
  }, [mangaProcessingList])

  return (
    <Layout style={{ background: 'white', paddingBottom: 40 }}>
      <h1 className='title'>絵本生成履歴</h1>
      <MediaFilter
        schedule={schedule}
        setSchedule={setSchedule}
        imgTypeSelected={imgTypeSelected}
        setImgTypeSelected={setImgTypeSelected}
        handleFilter={handleFilter}
      />
      {mangaProcessingSelectedList?.map(
        (item: MangaInforType, index: number) => (
          <MangaHistoryItem
            key={item?.id}
            item={item}
            index={index}
            showMangaReadingModal={showMangaReadingModal}
            setMangaSelectIndex={setMangaSelectIndex}
            isMangaProcessing={true}
          />
        )
      )}
      {mangaHistoryList?.map((item: MangaInforType, index: number) => (
        <MangaHistoryItem
          key={item?.id}
          item={item}
          index={index}
          showMangaReadingModal={showMangaReadingModal}
          setMangaSelectIndex={setMangaSelectIndex}
          onCheckBoxImage={onCheckBoxImage}
        />
      ))}
      {mangaProcessingSelectedList?.length <= 0 &&
        mangaHistoryList?.length <= 0 &&
        !isLoadingList && (
          <Flex vertical justify='center' align='center' className='mt-8'>
            <img
              src={require('../assets/images/empty-data.png')}
              className='w-[60px] h-[60px]'
            />
            <Typography.Text className='font-bold text-[20px] mt-2'>
              まだ絵本生成履歴がありません。
            </Typography.Text>
          </Flex>
        )}
      <Flex justify='center' align='flex-end' className='mt-7'>
        {Number(totalPages) > 1 && (
          <Pagination
            current={currentPage}
            onChange={onChangePage}
            total={Number(totalPages) * 10}
            showSizeChanger={false}
          />
        )}
      </Flex>
      <MangaReadingModal
        isShowModal={isShowMangaReadingModal}
        onCancel={hideMangaReadingModal}
        data={
          checkSelectedDataShowModal()
            ? mangaProcessingSelectedList[mangaSelectIndex]
            : (mangaHistoryList[mangaSelectIndex] as MangaInforType)
        }
        initialMangaBookData={selectedMangaBookData}
        selectCount={selectCount}
      />
    </Layout>
  )
}

export default PhotoLibraryScreen
