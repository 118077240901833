import { Col, Descriptions, Flex, Row, Space, Image } from 'antd'
import React from 'react'
import { UrlType } from '../types/manga-book.type'

type MangaReadingCardProps = {
  title?: string
  description?: string
  imageUrl?: UrlType[]
}

const MangaReadingCard = ({
  description,
  imageUrl,
  title
}: MangaReadingCardProps) => {
  title = title || 'Title'
  description =
    description ||
    `きょうも ひが あたたか ひです。ダイヤくんはへやであそやであそやであそやであそんでいます。\nそのすみで、ココちゃんは おひるね きょうも がたたかい ひです。\nダイヤくんは へやであんでいますそのすみで、ココちゃんは おひるね。\nあそんでいますそのすみで、ココちゃんは おひるね。あそんでいます。そのすみで、ココちゃんは おひるね。ダイヤくんは へやであそんでいますそのすみで、ココちゃんは おひるね。\nあそんでいますそのすみで、ココちゃんは おひるね。あそんでいます。そのすみで、ココちゃんは おひるね。`
  // imageUrl = imageUrl || 'https://via.placeholder.com/150'

  const transformDescription = (description: string) => {
    // find 。 and append new line after it
    // return description
    return description.replaceAll('\n', '').replaceAll('。', '。\n')
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <Row>
          {/* <Col xs={24} sm={12}> */}
          {/* <p className='p-4' style={{ fontSize: 24, whiteSpace: 'pre-wrap' }} >
                            {transformDescription(description)}
                        </p> */}
          <Col xs={24} sm={8}>
            <p
              className='whitespace-pre-line mr-2'
              style={{
                // writingMode: 'vertical-lr',
                // lineClamp: 10,
                // WebkitLineClamp: 10,
                // WebkitWritingMode: 'vertical-lr',
                width: '100%',
                fontSize: 22
              }}
            >
              {transformDescription(description)}
            </p>
          </Col>
          {/* </Col> */}
          <Col xs={24} sm={16}>
            {imageUrl?.map((item: UrlType) => (
              <Image
                className='p-4'
                src={item?.url}
                alt={title}
                style={{
                  objectFit: 'scale-down',
                  width: 480,
                  borderRadius: 24
                }}
                preview={false}
              />
            ))}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default MangaReadingCard
